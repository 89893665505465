
import { eoRendererMixin, metaDataMixin, dateFormatMixin, heroMixin } from '~/mixins'

export default {
  mixins: [
    dateFormatMixin,
    eoRendererMixin,
    metaDataMixin,
    heroMixin,
  ],
  layout: 'bijbelopen',
  async asyncData ({ $api, $config, $log, error }) {
    // Get homepage content
    const { data: { items: [ page ] }} = await $api.pages.getOneBySlug('homepage', {
      domain: $config.preprEnvironmentDomainBijbelopen,
    })

    // Get all recent articles
    const { data: { items: allArticles } } = await $api.articles.getAll({
      domain: $config.preprEnvironmentDomainBijbelopen,
      limit: 365,
    })
    if (!allArticles|| allArticles.length <= 0) {
      $log.warn(`Couldn't fetch any articles.`)
      return error({ statusCode: 404, message: 'Oeps! Geen overdenkingen gevonden.' })
    }

    return {
      ...page,
      allArticles,
    }
  },
  data () {
    return {
      allArticles: [],
      content: null,
      featuredVideo: null,
      heroUrl: null,
      id: null,
      intro: null,
      seo: null,
      shouldSkipNextTime: false,
      slug: null,
      social: null,
      subtitle: 'Tijd voor een overdenking',
      title: 'Bijbel Open',
    }
  },
  computed: {
    articleOfToday () {
      return this.allArticles.find(article => new Date(article.publishOn).toLocaleDateString() === new Date().toLocaleDateString()) || this.allArticles[0]
    },
  },
  watch: {
    shouldSkipNextTime () {
      if (process.client) {
        localStorage.setItem('bijbelOpenSkipIntro', this.shouldSkipNextTime)
      }
    },
  },
  mounted () {
    this.shouldSkipNextTime = localStorage.getItem('bijbelOpenSkipIntro') === 'true'
    if (this.shouldSkipNextTime) {
      this.$router.push(`/bijbelopen/${this.articleOfToday.slug}`)
    }
  },
}
